<template>
  <div class="container">
    <ul>
      <li>{{ isPC }}</li>
      <li>{{ isChrome }}</li>
      <li>{{ location }}</li>
      <li>{{ getProfile.name }}: {{ getProfile.email }}</li>
    </ul>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import ipGeo from "@/apis/ipGeo";

export default {
  metaInfo() {
    return {
      title: "SystemCheck - " + this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      location: ""
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    ...mapGetters("user", ["token", "getProfile"]),
    isPC() {
      let userAgentInfo = navigator.userAgent;
      let Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      let flag = userAgentInfo;
      // for (let v = 0; v < Agents.length; v++) {
      //   if (userAgentInfo.indexOf(Agents[v]) > 0) {
      //     flag = Agents[v];
      //     break;
      //   }
      // }
      return flag;
    },
    isChrome() {
      var isChromium = window.chrome;
      var winNav = window.navigator;
      var vendorName = winNav.vendor;
      var isOpera = typeof window.opr !== "undefined";
      var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
      var isIOSChrome = winNav.userAgent.match("CriOS");

      if (isIOSChrome) {
        // is Google Chrome on IOS
        return "Chrome";
      } else if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
      ) {
        // is Google Chrome
        return "Chrome";
      } else {
        // not Google Chrome
        let name = "";
        if (isIEedge) {
          name = isIEedge;
        }
        if (isOpera) {
          name = isOpera;
        }
        if (isIOSChrome) {
          name = isIOSChrome;
        }
        return name;
      }
    }
  },
  watch: {},

  async mounted() {
    const { iso_code } = await this.$store.dispatch("ipGeo/getGeoLocation");
    this.location = iso_code;
  },

  methods: {}
};
</script>

<style scoped></style>
